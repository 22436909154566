<template>
  <div data-app class="container" style="width: 90%">
    <v-form
      v-model="valid"
      ref="form1"
      lazy-validation
      id="accountType"
      class="mb-15"
    >
      <div class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
        <span>Sign In</span>
        <div class="form-group mb-form">
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="email"
                label="Email"
                :rules="[(v) => !!v || 'Item is required']"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Password"
                :rules="[(v) => !!v || 'Item is required']"
                required
                hint="At least 8 characters"
                @click:append="show1 = !show1"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </div>
      <span>
        By clicking “SIGN IN”, you agree to Choice Home Care’s
        <a
          role="button"
          tabindex="0"
          href="https://www.choicehomecare.com/terms-and-conditions/"
          target="_blank"
          ><u> Terms & Conditions</u></a
        >
        <!-- <a role="button" tabindex="0" @click="Terms()"
          ><u> Terms & Conditions</u></a
        > -->
      </span>
      <br />
      <div class="text-center">
        <v-btn
          rounded
          class="btn btn-success mr-3 mb-9 mt-5"
          style="width: 100%; color: #ffff; height: 60px"
          @click="SignIn()"
        >
          Sign In
          <span v-if="isLogging">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </span>
        </v-btn>
      </div>
      <router-link to="/ForgotPassword">Forgot Password</router-link>
    </v-form>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email, maxLength } from "vuelidate/lib/validators";
import { getToken, saveToken, destroyToken } from "../../core/services/Cookie";
import axios from "axios";
var today = new Date();
export default {
  mixins: [validationMixin],
  validations: {
    type: {
      required,
    },
    checkbox: {
      checked(val) {
        return val;
      },
    },
  },
  mounted() {
    fetch("https://geolocation-db.com/json/")
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        this.deviceId = res.IPv4;
      });
  },

  data() {
    return {
      valid: true,
      email: "",
      show1: false,
      password: "",
      isLogging: false,
      deviceId: "",
    };
  },
  beforeCreate() {
    var token = JSON.parse(getToken());
    if (token != null && token != undefined) {
      this.$store.dispatch("AuthenticateUser", token).then((response) => {
        if (response.status) {
          this.$router.push({ name: "dashboard" });
        }
      });
    }
  },
  computed: {},
  destroyed() {},
  methods: {
    next() {
      this.$v.$touch();
      if (this.$refs.form.validate()) {
        this.showForm2 = true;
        this.showForm1 = false;
      }
    },
    createAccount() {
      this.$router.push({ name: "signUp" });
    },
    Terms() {
      this.$router.push({ name: "termandcondition" });
    },
    forgotPassword() {
      this.$router.push({ name: "forgotPassword" });
    },
    SignIn() {
      this.isLogging = true;
      this.$store
        .dispatch("AuthenticateUser", {
          Email: this.email,
          Password: this.password,
          DeviceIp: this.deviceId,
        })
        .then((response) => {
          if (response.status) {
            if (response.data == null) {
              this.$router.push({
                name: "verifyOTP",
                params: {
                  text: this.email,
                },
              });
            } else {
              this.$router.push({ name: "dashboard" });
            }
          } else {
            Swal.fire({
              title: "",
              text: response.message,
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
            });
          }
          this.isLogging = false;
        });

      //   Swal.fire({
      //     title: "",
      //     text: "Account has been Created Successfully!",
      //     icon: "success",
      //     confirmButtonClass: "btn btn-secondary",
      //   });
    },
  },
};
</script>
<style>
.error--text {
  color: rgba(252, 9, 9, 0.7) !important;
}
a:not([href]):not([class]) {
  color: #1b5d77 !important;
  text-decoration: none;
}
</style>
